export const translations = {
  RU: {
    next: "Далее",
    survey: "Опрос",
    yourAnswer: "Ваш ответ...",
    enterAnswer: "Введите ответ...",
    finished: "завершен",
    yes: "Да",
    no: "Нет",
    closeTabText: "Опрос завершен, закройте вкладку используя функции своего браузера",
    selectFile: "Выбрать файл",
    maxFileWeightError: "Максимальный размер файла: 3 mb",
    min: "Мин.",
    max: "Макс.",
    notFoundPageTxt: "Опрос не найден или уже завершен.",
    answerRequired: "Ответ обязателен",
    iin: "ИИН",
    "500ErrorDescription": "Произошла непредвиденная ошибка сервера…",
    "404ErrorDescription": "К сожалению, запрашиваемая страница не найдена...",
    errorPageButtonLabel: "Перейти на surveysuite.io",
    select: "Выбрать",
    footer: {
      beforeText: "Создано на ",
      afterText: " ",
    },
    makeChoice: "Пожалуйста, сделайте выбор перед продолжением.",
    maxOptionLimit: "Необходимо выбрать %{limit} вариантов ответа",
  },
  EN: {
    next: "Next",
    survey: "Survey",
    yourAnswer: "Your answer...",
    enterAnswer: "Enter answer...",
    finished: "finished",
    yes: "Yes",
    no: "No",
    closeTabText: "Survey completed, close the tab using your browser functionality.",
    selectFile: "Select file",
    maxFileWeightError: "Maximum file size: 3 mb",
    min: "Min.",
    max: "Max.",
    notFoundPageTxt: "Survey not found or completed.",
    answerRequired: "Reply required",
    iin: "IIN",
    "500ErrorDescription": "An unexpected server error has occurred…",
    "404ErrorDescription": "Unfortunately, the requested page was not found…",
    errorPageButtonLabel: "Visit surveysuite.io",
    select: "Select",
    footer: {
      beforeText: "Powered by ",
      afterText: " ",
    },
    makeChoice: "Please make your choice before continuing.",
    maxOptionLimit: "You need to choose %{limit} answer options",
  },
  KK: {
    next: "Келесі",
    survey: "Сауалнама",
    yourAnswer: "Сіздің жауабыңыз...",
    enterAnswer: "Жауапты енгізіңіз...",
    finished: "аяқталды",
    yes: "Иә",
    no: "Жоқ",
    closeTabText: "Сауалнама аяқталды, браузеріңіздің функцияларын пайдаланып қойындыны жабыңыз.",
    selectFile: "Файлды таңдаңыз",
    maxFileWeightError: "Максималды файл өлшемі: 3 Mb",
    min: "Мин.",
    max: "Макс.",
    notFoundPageTxt: "Сауалнама табылмады немесе аяқталмады.",
    answerRequired: "Жауап қажет",
    iin: "ЖСН",
    "500ErrorDescription": "Күтпеген сервер қатесі орын алды…",
    "404ErrorDescription": "Өкінішке орай, сұралған бет табылмады…",
    errorPageButtonLabel: "Surveysuite.io ашу",
    select: "Select",
    footer: {
      beforeText: " ",
      afterText: " платформасында құрылған",
    },
    makeChoice: "Жалғастырудан бұрын таңдауыңызды жасаңыз.",
    maxOptionLimit: "%{limit} жауап нұсқаларын таңдау керек",
  },
  TR: {
    next: "Daha öte",
    survey: "Anket",
    yourAnswer: "Cevabınız...",
    enterAnswer: "Cevabınızı girin...",
    finished: "tamamlanmış",
    yes: "Evet",
    no: "Hayir",
    closeTabText: "Anket tamamlandı, tarayıcınızın işlevlerini kullanarak sekmeyi kapatın",
    selectFile: "Bir dosya seçin",
    maxFileWeightError: "Maksimum dosya boyutu: 3 mb",
    min: "Dak.",
    max: "Maks.",
    notFoundPageTxt: "Anket bulunamadı veya tamamlanmadı.",
    answerRequired: "Yanıt gerekli",
    iin: "IIN",
    "500ErrorDescription": "An unexpected server error has occurred…",
    "404ErrorDescription": "Unfortunately, the requested page was not found…",
    errorPageButtonLabel: "Visit surveysuite.io",
    select: "Select",
    footer: {
      beforeText: " ",
      afterText: " platformunda oluşturuldu",
    },
    makeChoice: "Devam etmeden önce lütfen seçiminizi yapın.",
    maxOptionLimit: "%{limit} cevap seçeneği seçmek gerekir",
  },
  UZ: {
    next: "Keyinchalik",
    survey: "Tadqiqot",
    yourAnswer: "Javobingiz...",
    enterAnswer: "Javobingizni kiriting...",
    finished: "yakunlandi",
    yes: "Ha",
    no: "Yo'q",
    closeTabText: "Soʻrov tugallandi, brauzeringiz funksiyalaridan foydalanib yorliqni yoping",
    selectFile: "Faylni tanlang",
    maxFileWeightError: "Maksimal fayl hajmi: 3 mb",
    min: "Min.",
    max: "Maks.",
    notFoundPageTxt: "So'rov topilmadi yoki to'ldirilmadi.",
    answerRequired: "Javob kerak",
    iin: "IIN",
    "500ErrorDescription": "An unexpected server error has occurred…",
    "404ErrorDescription": "Unfortunately, the requested page was not found…",
    errorPageButtonLabel: "Visit surveysuite.io",
    select: "Select",
    footer: {
      beforeText: " ",
      afterText: " platformasida yaratilgan",
    },
    makeChoice: "Davom etishdan oldin tanlovingizni qiling.",
    maxOptionLimit: "%{limit} javob variantlarini tanlash kerak",
  },
  FR: {
    next: "Plus loin",
    survey: "Enquête",
    yourAnswer: "Votre Réponse...",
    enterAnswer: "Entrez votre réponse...",
    finished: "complété",
    yes: "Oui",
    no: "Non",
    closeTabText: "Enquête terminée, fermez l'onglet en utilisant les fonctions de votre navigateur",
    selectFile: "Sélectionner un fichier",
    maxFileWeightError: "Taille de fichier maximale : 3 Mo",
    min: "Min.",
    max: "Max.",
    notFoundPageTxt: "Enquête introuvable ou inachevée.",
    answerRequired: "Réponse requise",
    iin: "IIN",
    "500ErrorDescription": "An unexpected server error has occurred…",
    "404ErrorDescription": "Unfortunately, the requested page was not found…",
    errorPageButtonLabel: "Visit surveysuite.io",
    select: "Select",
    footer: {
      beforeText: "Créé sur le programme ",
      afterText: " ",
    },
    makeChoice: "Veuillez faire votre choix avant de continuer.",
    maxOptionLimit: "Vous devez choisir %{limit} options de réponse",
  },
};
