import React, { FC, ReactNode, useCallback, useEffect } from "react";
import { useActions } from "../hooks/useActions";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../helpers/cookiesManager";
import { APP_PREFIX } from "../helpers";
import UAParser from "ua-parser-js";
import { UserMetadataType } from "../types/UserMetadataType";
import { SurveyVariants } from "../types/surveyVariants";

const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getNextQuestion, startAnonymousSurvey } = useActions();
  const location = useLocation();
  const navigate = useNavigate();

  const getUserMetadata = useCallback((): UserMetadataType => {
    const parser = new UAParser();
    const result = parser.getResult();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userLanguage = navigator.language;

    return {
      deviceMetadata: result.device,
      browserLanguage: userLanguage,
      operationalSystem: result.os.name,
      browserName: result.browser.name,
      timezone: userTimeZone,
    };
  }, []);

  const onLoad = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const instanceId = location.pathname.split(`${APP_PREFIX}/`).at(1);
    const cachedInstance = getCookie(instanceId ?? "");
    if (cachedInstance?.key && cachedInstance?.value && params['variant'] !== SurveyVariants.EMULATOR) {
      navigate(APP_PREFIX.concat("/", cachedInstance.value));
    } else if (instanceId?.startsWith("srv_") && params['receiver']) {
      startAnonymousSurvey({
        receiver: params['receiver'],
        surveyId: instanceId,
        navigate,
        metadata: getUserMetadata(),
        variant: params['variant'],
        channel: params['channel']
      });
    } else if (instanceId) {
      getNextQuestion({ instanceId });
    }
  }, [location, navigate, startAnonymousSurvey, getNextQuestion, getUserMetadata]);

  useEffect(onLoad, [location.pathname]);

  return <>{children}</>;
};

export default AppProvider;
