import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const MarkdownParser = ({ mixedString }: { mixedString: string }) => {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]} className={"markdown-content"}>
      {mixedString}
    </ReactMarkdown>
  );
};
export default MarkdownParser;
