import { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { TypeEnum } from "../../../types/answerTemplate";
import { useActions } from "../../../hooks/useActions";
import { useParams } from "react-router-dom";
import validatePhone from "../../../helpers/validatePhone";
import validateEmail from "../../../helpers/validateEmail";
import { Button, message } from "antd";
import getInvertedColorStyle from "../../../helpers/getInvertedColorStyle";
import { I18n } from "react-redux-i18n";
import validateIin from "../../../helpers/validateIin";
import { testValueUsingCustomRegExp } from "../../../helpers/testValueUsingCustomRegExp";

const OTHER_ANSWER_KEY = "other";

type ButtonsComponentProps = {
  loading: boolean;
  isAnswerMoved: boolean;
};

const ButtonsComponent: FC<ButtonsComponentProps> = ({ loading, isAnswerMoved }) => {
  const { instanceId } = useParams();
  const { answerQuestion } = useActions();
  const { question, answer, customAnswer } = useSelector((state: RootState) => state.questions);
  const maxOptionLimit = question?.answerTemplate?.maxOptionLimit;

  const isTemplate = useCallback(
    (type$: TypeEnum) => question?.answerTemplate?.type === type$,
    [question?.answerTemplate?.type],
  );

  const isAnswerValid = useCallback(() => {
    const isBasicTemplate = () =>
      isTemplate(TypeEnum.NPS) ||
      isTemplate(TypeEnum.EMAIL) ||
      isTemplate(TypeEnum.IIN) ||
      isTemplate(TypeEnum.NUMBER) ||
      isTemplate(TypeEnum.TEXT) ||
      isTemplate(TypeEnum.DATE) ||
      isTemplate(TypeEnum.RATING) ||
      isTemplate(TypeEnum.LOYALTY_INDEX) ||
      isTemplate(TypeEnum.DATETIME);

    const validateBasicTemplate = () => {
      if (!question?.answerTemplate?.customAnswerIsRequired && !answer?.answerValue) {
        return true;
      }
      if (!answer?.answerValue) {
        return false;
      }

      switch (true) {
        case isTemplate(TypeEnum.NUMBER):
          return validatePhone(answer.answerValue);
        case isTemplate(TypeEnum.IIN):
          return validateIin(answer.answerValue);
        case isTemplate(TypeEnum.EMAIL):
          return validateEmail(answer.answerValue);
        case isTemplate(TypeEnum.TEXT) && question?.answerTemplate?.customRegexp:
          return testValueUsingCustomRegExp({
            regexp: question?.answerTemplate?.customRegexp,
            value: answer?.answerValue,
          });
        default:
          return true;
      }
    };

    const validateChoiceTemplate = (type: any) => {
      if (!question?.answerTemplate?.customAnswerIsRequired) {
        return true;
      }
      if (type === TypeEnum.SINGLE_CHOICE) {
        return !!answer?.choices || !!answer?.answerValue;
      }
      if (type === TypeEnum.MULTIPLE_CHOICE) {
        const hasChoices = answer?.choices && answer.choices.length > 0;
        if (!hasChoices) {
          return false;
        }
        if (maxOptionLimit) {
          return answer?.choices?.length === maxOptionLimit;
        }
        return true;
      }
      return false;
    };

    const validateSpecialTemplate = (type: any) => {
      if (!question?.answerTemplate?.customAnswerIsRequired) {
        return true;
      }
      if (type === TypeEnum.CSI) {
        return answer?.csiAnswers && answer.csiAnswers.length > 0
          ? answer.csiAnswers.every((csiAnswer) =>
              csiAnswer?.criterias?.every((criteria) => criteria?.score !== undefined),
            )
          : false;
      }
      return !!answer?.answerValue;
    };

    if (isBasicTemplate()) {
      return validateBasicTemplate();
    } else if (isTemplate(TypeEnum.SINGLE_CHOICE) || isTemplate(TypeEnum.MULTIPLE_CHOICE)) {
      return validateChoiceTemplate(
        isTemplate(TypeEnum.SINGLE_CHOICE) ? TypeEnum.SINGLE_CHOICE : TypeEnum.MULTIPLE_CHOICE,
      );
    } else if (isTemplate(TypeEnum.LOYALTY_INDEX) || isTemplate(TypeEnum.RATING) || isTemplate(TypeEnum.CSI)) {
      return validateSpecialTemplate(
        isTemplate(TypeEnum.LOYALTY_INDEX)
          ? TypeEnum.LOYALTY_INDEX
          : isTemplate(TypeEnum.RATING)
          ? TypeEnum.RATING
          : TypeEnum.CSI,
      );
    } else if (isTemplate(TypeEnum.RANGING)) {
      return !(!isAnswerMoved && question?.answerTemplate?.customAnswerIsRequired);
    } else if (
      !isTemplate(TypeEnum.START_BLOCK) &&
      !isTemplate(TypeEnum.END_BLOCK) &&
      !isTemplate(TypeEnum.INFO_BLOCK)
    ) {
      return !!answer?.answerValue || !!customAnswer;
    }
    return true;
  }, [question, answer, customAnswer, isTemplate]);

  const handleAnswer = useCallback(() => {
    const answerValid = isAnswerValid();

    if (!answerValid) {
      if (answer?.choices && answer.choices.length > 0 && maxOptionLimit && isTemplate(TypeEnum.MULTIPLE_CHOICE)) {
        return message.error(I18n.t("maxOptionLimit", { limit: maxOptionLimit }));
      } else {
        return message.error(I18n.t("answerRequired"));
      }
    }

    if (instanceId && question?.questionId) {
      let answerObj = answer;

      if (isTemplate(TypeEnum.SINGLE_CHOICE) || isTemplate(TypeEnum.MULTIPLE_CHOICE)) {
        const choices = answer?.choices?.map((it) => String(it)) ?? [];
        answerObj = {
          csiAnswers: [],
          answerValue: customAnswer,
          choices: !customAnswer ? choices : [...choices, OTHER_ANSWER_KEY],
        };
      } else if (isTemplate(TypeEnum.IIN)) {
        answerObj = { ...answer, answerValue: answer?.answerValue?.replace(/\s/g, "") };
      }

      answerQuestion({
        instanceId,
        answer: answerObj,
        questionId: question.questionId,
      });
    }
  }, [instanceId, question, customAnswer, answer, isTemplate, isAnswerValid]);

  return (
    <div className={"btn-container"}>
      <Button type={"primary"} onClick={handleAnswer} loading={loading}>
        <span style={getInvertedColorStyle()}>{question?.answerTemplate?.actionLabel ?? I18n.t("next")}</span>
      </Button>
    </div>
  );
};

export default ButtonsComponent;
